import { type NotificationType } from './contexts/Notification.context';
import type { PushContext } from './modules/push2emr/types';
import type { AnnotationLineGroup } from './pages/summary/med-features/lab-trend-view/lab-graph/annotations-types';
import type {
	Diagnosis as PushToEmrDiagnosis,
	DiagnosisStatus,
	DiagnosisStatus as PushToEmrDiagnosisStatus,
	HCCs,
	SourceData,
	SourceType,
	SourceType as PushToEmrDiagnosisSourceType,
} from './pages/summary/med-features/push-to-emr/types';
import { type DxDict } from './stores/pushToEmrStore';
import type { DiagnosisAction, HccVersions } from '@dxcapture/core';
import { type DashboardNamesType } from '@navina/analytics-frontend';
import { type ArgsProps } from 'antd/es/notification';

export type AddonButtonClickBehaviorTypes = 'tab' | 'window';

interface ProviderName {
	readonly lastName: string;
	readonly firstName: string;
}

interface PreviousEncounter {
	readonly date: string;
	readonly reasonForVisit: string;
	readonly providers: ReadonlyArray<ProviderName>;
}

interface ProblemGraphDetails {
	readonly date: string;
	readonly observationValue?: string | number;
	readonly isNormal?: boolean;
}

export interface ProblemGraphData {
	readonly title: string;
	readonly data: ProblemGraphDetails;
}

interface FileData {
	readonly path: string;
	readonly numOfPages: number;
	readonly name: string;
	readonly date: string;
	readonly bottomLine: string;
}

export interface ProblemVitalsType {
	observationValue: string; // TODO - make readonly
	readonly date: string;
	readonly title: string;
}

export interface ProblemDocList {
	readonly [name: string]: Array<ProblemDocType>; // TODO - make ReadonlyArray
}

export interface ProblemType {
	readonly title: string;
	readonly indicateSmoker: boolean;
	readonly indicateAlcoholism: boolean;
	readonly icd: string;
	readonly snomed: string;
	readonly medications?: ReadonlyArray<string>;
	readonly medsExacerbate?: ReadonlyArray<string>;
	readonly immunizationsReccomendations?: ReadonlyArray<string>;
	readonly vitals?: Array<ProblemVitalsType>; // TODO - make ReadonlyArray
	readonly files?: ReadonlyArray<FileData>;
	readonly other?: ReadonlyArray<string>;
	readonly graphsData: ReadonlyArray<ProblemGraphData>;
	readonly docs?: ProblemDocList;
	readonly groupedProblems?: ReadonlyArray<{ readonly name: string; readonly snomedCode: string }>;
}

interface IcdDataType {
	readonly code: string;
	readonly hccWeight: number;
	readonly hccGroup?: string;
	readonly description: string;
}

interface EncountersMissingDiagnosesType {
	readonly data: { readonly encounterDate: string; readonly encounterId: number };
	readonly snomedCode: string;
	readonly snomedName: string;
	readonly icdCode: ReadonlyArray<string>;
	readonly icdData: ReadonlyArray<IcdDataType>;
	readonly rankScore: number;
}

interface ObservationsMissingDiagnosesType {
	readonly icdCode: ReadonlyArray<string>;
	readonly snomed: { readonly code: string; readonly description: string };
	readonly icdData: ReadonlyArray<IcdDataType>;
	readonly data: unknown;
}

interface MsoRecommendationBaseType {
	readonly suggestionType: 'Suspect' | 'Chronic';
	readonly providerFirstName: string;
	readonly providerLastName: string;
	readonly date: string;
	readonly memo: string;
	readonly msoName: string;
}

export interface AgilonNonDxType extends MsoRecommendationBaseType {
	readonly navinaSuggestionsId: string;
	readonly agilonSuggestionId: string;
	readonly code: string;
	readonly lastModified: string;
}

export type AgilonNonDxUserSelectionType = 'agree' | 'disagree' | 'ignore';

export interface AgilonNonDxStatusDataType {
	readonly agilonSuggestionId: string;
	readonly createdAt: string;
	readonly navinaSuggestionId: string;
	status: AgilonNonDxUserSelectionType; // TODO - make readonly
	updatedAt: string; // TODO - make readonly
	readonly updatedBy?: string;
}

interface ConsultMissingDiagnosesType {
	readonly fileId: string;
	readonly fileName: string;
	readonly fileDate: string;
	readonly fileLoinc: string;
	readonly fileLoincName: string;
	readonly fileLabel: string;
	readonly lineContentOccuranceInDoc: number;
	readonly icd: string;
	readonly snomedCode: string;
	readonly description: string;
	readonly modelScore: number;
	readonly icd10CodeLineContent: string;
}

interface RafGapType {
	readonly icd_code: ReadonlyArray<string>;
	readonly snomed_code?: string;
	readonly data: { readonly modificationDate: string };
	readonly icdData: ReadonlyArray<IcdDataType>;
	readonly snomedCode: string;
	readonly operators?: ReadonlyArray<InferredPmdLabOperatorType>;
	readonly hccGroup: string;
	readonly hccWeight: number;
	readonly billable: boolean;
	readonly billableDescendants: ReadonlyArray<BillableDescendant>;
}

interface InferredPmdLabOperatorDataType {
	readonly value: string;
	readonly operator: string;
	readonly date: string;
	readonly source: string;
	readonly fileId?: string;
	readonly fileName?: string;
}

interface InferredPmdMedicationOperatorDataType {
	readonly drugName: string;
	readonly dosage: string;
	readonly operator: string;
	readonly date: string;
	readonly source: string;
}

interface InferredPmdLabOperatorType {
	readonly name: string;
	readonly result: boolean;
	readonly data: ReadonlyArray<InferredPmdLabOperatorDataType>;
}

interface InferredPmdMedicationOperatorType {
	readonly name: string;
	readonly result: boolean;
	readonly data: ReadonlyArray<InferredPmdMedicationOperatorDataType>;
}

interface WithDescription {
	readonly description: string;
}

export interface SnomedCodeAndDescription extends WithDescription {
	readonly snomedCode: string;
}

interface CodeAndDescription extends WithDescription {
	readonly code: string;
}

interface InferredMissingDiagnosesType {
	readonly operators: ReadonlyArray<InferredPmdLabOperatorType | InferredPmdMedicationOperatorType>;
	readonly icdCode: string;
	readonly hccWeight: number;
	readonly icdData: ReadonlyArray<IcdDataType>;
	readonly plInclusion?: ReadonlyArray<SnomedCodeAndDescription>;
}

export interface NoteData {
	readonly text: string;
	readonly authorId?: string;
	readonly authorName: string;
	readonly authorType: 'provider' | 'coder';
	shouldPush: boolean; // TODO - make readonly
	readonly noteDate: string;
	readonly insertionDate: unknown;
	readonly addedInPostVisit?: boolean;
}

export interface DxFromEmr {
	readonly icd10: CodeAndDescription;
	readonly snomed: CodeAndDescription;
}

interface ConsolidatedMissingDiagnosesType {
	readonly icdCode: string;
	readonly icdDescription: string;
	readonly hccWeight: number;
	readonly hccGroup: string;
	readonly snomedDescription: string;
	readonly reasons: {
		readonly consult?: ConsultMissingDiagnosesType;
		readonly inferred?: ObservationsMissingDiagnosesType | InferredMissingDiagnosesType;
		readonly encounter?: EncountersMissingDiagnosesType;
	};
	readonly icdData: ReadonlyArray<DocIcdData>;
	readonly sourceData?: SourceData;
	readonly date?: Date;
	readonly billable?: boolean;
	readonly billableDescendants?: ReadonlyArray<BillableDescendant>;
}

export interface RecommendedDiagnosis {
	readonly icdCode: string;
	readonly icdDescription: string;
	readonly hccWeight: number;
	readonly hccGroup: string;
	readonly snomedCode: string;
	readonly snomedDescription: string;
	readonly sourceData?: SourceData;
	readonly diagnosisSources: Array<DiagnosisSources>; // TODO - make ReadonlyArray
	readonly icdData: ReadonlyArray<DocIcdData>;
	readonly date: Date;
	readonly billable?: boolean;
	readonly billableDescendants?: ReadonlyArray<BillableDescendant>;
	readonly isPrimary: boolean;
	readonly addressedGroup?: DiagnosisAddressedGroup;
	readonly noteData?: NoteData;
	readonly companionOrder: number;
	readonly primaryDx: string;
	readonly companionDxs: ReadonlyArray<string>;
	readonly isNavinaCoupledSuggestion: boolean;
	readonly isCoupledSuggestion: boolean;
	readonly companionAddressedGroup: DiagnosisAddressedGroup;
	readonly dxId: string;
	readonly addedInPostVisit?: boolean;
}

interface SuggestedDiagnoses {
	readonly dxSuggestions: ReadonlyArray<RecommendedDiagnosis>;
	readonly isValid: boolean;
	readonly actionsHistory?: ReadonlyArray<any>;
}

// TODO - change to enum
export type DiagnosisSourceType =
	| 'pl_gap'
	| 'raf_gap'
	| 'imaging_inferred'
	| 'hie_encounters'
	| 'external_suggestion'
	| 'ner_from_consults'
	| 'claims'
	| 'claim_cms'
	| 'consults'
	| 'agilon'
	| 'inferred'
	| 'manually_from_document';

interface DocumentMetadata {
	readonly name: string;
	readonly id: string;
	readonly date: string | null;
	readonly searchTerm?: string;
	readonly searchTermOccurrence?: number;
}

export interface DiagnosisSourceRow {
	readonly key: string;
	readonly value: string | null;
	readonly contentType?: 'text/plain' | 'text/html' | 'text/markdown';
	readonly label?: string;
	readonly document?: DocumentMetadata;
}

type DiagnosisSourceTable = ReadonlyArray<{
	readonly title: string;
	readonly rows: ReadonlyArray<DiagnosisSourceRow>;
	readonly date?: string | null;
}>;

export interface DiagnosisSources {
	readonly sourceName: string;
	readonly date?: string | null;
	readonly type: DiagnosisSourceType;
	readonly document?: DocumentMetadata;
	readonly data: DiagnosisSourceTable;
	readonly extraData?: ReadonlyArray<DiagnosisSourceRow>;
}

export type SortValues = 'Recapture first' | 'Suspect first' | 'Condition';

export type ConfidenceLabel =
	| 'Recapture'
	| 'Suspect'
	| 'Suspect: Condition documented elsewhere'
	| 'Suspect: Meets diagnostic criteria'
	| 'Suspect: Clinical correlation needed'
	| null;

export type ConfidenceLabelHoverText =
	| 'Condition previously documented in the EHR'
	| 'Requires clinical validation'
	| 'Condition is not documented in the problem list or previous encounters in this EHR'
	| 'See Sources for more information'
	| 'Condition suspected, clinical correlation required'
	| '';

type confidenceLabelMap = {
	readonly [key in ConfidenceLabel]: ConfidenceLabelHoverText;
};

// TODO - this is not a type, move somewhere else
export const confidenceLabelToolTipDict: confidenceLabelMap = {
	Recapture: 'Condition previously documented in the EHR',
	Suspect: 'Requires clinical validation',
	'Suspect: Condition documented elsewhere':
		'Condition is not documented in the problem list or previous encounters in this EHR',
	'Suspect: Meets diagnostic criteria': 'See Sources for more information',
	'Suspect: Clinical correlation needed': 'Condition suspected, clinical correlation required',
};

// TODO - this is not a type, move somewhere else
export const InAppExplanationIconTexts = {
	ReaddressDiagnosesInAppExplanation: 'Diagnoses that have been suggested by the coder to be readdressed',
	NewHCCInAppExplanation: 'Diagnoses from HCC groups that have not yet been addressed this year',
	NewHCCMidYearInAppExplanation: 'Diagnoses from HCC groups that have not yet been addressed this year',
	ExistingHCCInAppExplanation: 'Diagnoses from HCC groups that have been addressed this year',
	ExistingHCCMidYearInAppExplanation: 'Diagnoses from HCC groups that have been addressed this year',
	ProviderRejectedInAppExplanation: 'Diagnoses that had been previously rejected by the provider',
	CoderRejectedInAppExplanation: 'Diagnoses that had been previously rejected by a coder',
	AgilonNotesInAppExplanation: 'Clinical and care recommendations for the provider to agree or disagree with',
	NoHCCInAppExplanation: 'Non-HCC Diagnosis suggestions',
	CartProviderInAppExplanation:
		'All the selected diagnoses for this encounter. Clicking Add to Encounter will send to the EMR',
	CartCoderInAppExplanation:
		'All the selected diagnoses for this encounter. Clicking "Finish Scrub" will send the diagnosis suggestions to the provider',
};

export interface DiagnosisActionStatus {
	icdCode: SuggestedDiagnosis['icdCode'];
	initialStatus: DiagnosisStatus;
	currentStatus: DiagnosisStatus;
	initialDiagnosisAction: SuggestedDiagnosis['diagnosisAction'];
	currentDiagnosisAction: SuggestedDiagnosis['diagnosisAction'];
	isBackgroundAction?: boolean;
}

export interface SuggestedDiagnosis {
	readonly dxId: string;
	readonly icdCode: string;
	readonly icdDescription: string;
	readonly dxDescription?: string;
	readonly hccs: HCCs;
	readonly snomedCode: string;
	readonly snomedDescription: string;
	readonly sourceData: SourceData;
	readonly source?: string;
	diagnosisSources: Array<DiagnosisSources>; // TODO - make readonly AND ReadonlyArray
	readonly suggestionDateTime: string;
	readonly billable: boolean;
	readonly billableDescendants: Array<BillableDescendant>; // TODO - make ReadonlyArray
	readonly diagnosisOptionalIcds?: Array<OptionalIcd>; // TODO - make ReadonlyArray
	readonly chosenOptionalIcd: OptionalIcd;
	readonly isOptionalIcd: boolean;
	readonly isChosenOptionalIcd: boolean;
	readonly isBillableSubDx: boolean;
	readonly addressedGroup: DiagnosisAddressedGroup | null;
	noteData: NoteData | null; // TODO - make readonly
	readonly addedInPostVisit: boolean;
	readonly type: SourceType;
	readonly isSyncedFromEmr: boolean;
	readonly showInCart: boolean;
	readonly showInInsights: boolean;
	readonly isValid: boolean;
	readonly overrideFilter: string | null;
	readonly invalidOverride: boolean;
	readonly actionsHistory: ReadonlyArray<any>;
	status: DiagnosisStatus | null; // TODO - make readonly
	readonly statusUpdatedAt: string | null;
	readonly statusUpdatedBy: string | null;
	readonly actionUsername: string;
	readonly statusUpdatedTimestamp: number;
	readonly statusAddedTimestamp: number;
	readonly captionInfo?: string | null;
	readonly lastCoderStatus: DiagnosisStatus | null;
	readonly lastCoderStatusUpdatedAt: string | null;
	readonly lastProviderStatus: DiagnosisStatus | null;
	readonly lastProviderStatusUpdatedAt: string | null;
	readonly isNavinaCoupledSuggestion: boolean;
	isCoupledSuggestion: boolean;
	companionOrder: number;
	isPrimary: boolean; // TODO - make readonly
	primaryDx: string | null;
	companionDxs: Array<string> | null; // TODO - make ReadonlyArray
	companionAddressedGroup: DiagnosisAddressedGroup | null;
	readonly isCoupledSuggestionCart: boolean;
	readonly isPrimaryCart: boolean | null;
	readonly companionOrderCart: number | null;
	readonly companionDxsCart: Array<string> | null; // TODO - make ReadonlyArray
	readonly primaryDxCart: string | null;
	readonly companionAddressedGroupCart: DiagnosisAddressedGroup | null;
	readonly numberOfSources: number;
	// Remodel properties
	suggestionId: string;
	suggestionUid: string;
	diagnosisUid: string;

	// Carry forward types
	readonly isManualFromPreviousAppointments?: boolean;
	readonly isFromRescheduledAppointment?: boolean;
	readonly rescheduledAppointmentStartTimeUnix?: number;
	readonly lastProviderStatusUpdatedByName: string | null;
	readonly lastCoderStatusUpdatedByName: string | null;

	readonly isCoderStatusFromCurrentSid?: boolean;
	readonly isProviderStatusFromCurrentSid?: boolean;

	readonly diagnosisAction: DiagnosisAction | 'NO_ACTION';

	readonly greyedOutReason?: string;
	readonly companionId: string | null;
	readonly confidenceLabel: ConfidenceLabel;
}

export interface BillableDescendant {
	readonly billable: boolean;
	readonly icdCode: string;
	readonly icdDescription: string;
	readonly hccs: HCCs;
}

export interface OptionalIcd {
	readonly icdCode: string;
	readonly icdDescription: string;
	readonly hccs: HCCs;
	readonly hasIcd?: boolean;
	readonly snomedCode: string;
	readonly addressedGroup?: DiagnosisAddressedGroup;
}

interface WithThreshHold {
	readonly threshold: number;
}

interface MissingDiagnosesType {
	// This is the old structure of pmd, not supported anymore (25/02/21, Ron)
	// encounters: EncountersMissingDiagnosesType[];
	// observations: ObservationsMissingDiagnosesType[];
	readonly previousYears: ReadonlyArray<RafGapType>;
	readonly encountersConfig: WithThreshHold;
	readonly consultsConfig: WithThreshHold;
	readonly consolidatedPmd: { readonly [snomedCode: string]: ConsolidatedMissingDiagnosesType };
	readonly hccRecommendations: ReadonlyArray<SuggestedDiagnoses>;
}

export interface RafDiagnosis {
	readonly icdCode?: string;
	readonly description: string;
	readonly hccs: HCCs;
	readonly date?: string;
	readonly snomedCode?: string;
	hccGroup?: string; // TODO - make readonly
	readonly hccWeight?: number;
}

export type DiagnosisAddressedGroup =
	| 'NewHCCGroup'
	| 'AddressedHCCGroup'
	| 'ReAddressed'
	| 'RejectedByProvider'
	| 'RejectedByCoder'
	| 'NoHcc'
	| 'none';

interface RafType {
	raf?: number; // TODO - make readonly

	readonly rafUnion?: number;
	readonly updatedRaf?: number;
	readonly previousYearRaf?: number;
	readonly elig?: 'CND' | 'CNA';
	readonly currentYearDiagnoses?: Array<RafDiagnosis>; // TODO - make ReadonlyArray
	readonly previousYearDiagnoses?: Array<RafDiagnosis>; // TODO - make ReadonlyArray
	readonly rafCalculationPeriod?: 'half-year' | 'year' | null;
	readonly currentRafV28?: number;
	readonly currentRafV24?: number;
}

export type DemographicsType = {
	readonly patientEmrId: string;
	readonly dataSource: number;
	readonly multiplePatientIds: ReadonlyArray<string>;
	readonly age: number;
	readonly appointmentEmrDepartmentId: number;
	readonly martialStatus: string;
	readonly occupation: string;
	readonly sex: string;
	readonly firstName: string;
	readonly lastName: string;
	readonly dateOfBirth: string;
	readonly race: string;
	readonly insurances?: string;
	readonly insuranceType?: string;
};

export interface LabDataType {
	readonly value: string;
	readonly originalValue: string;
	readonly low: number;
	readonly high: number;
	readonly rangeType: string;
	readonly date: string;
	readonly units: string;
	readonly isNormal: boolean;
	readonly producerId?: string;
	readonly source?: LabSource;
	readonly sourceDetails?: string;
	readonly history?: ReadonlyArray<TrendLab>;
}

export interface Observation {
	readonly high: number | null;
	readonly low: number | null;
	readonly originalValue: string;
	readonly value: number;
	readonly date: Date;
	readonly units: null | string;
	readonly producerId: null;
	readonly rangeType: string | null;
	readonly refRangeSource: string | null;
	readonly isNormal?: boolean;
	readonly observationNote?: null;
}

export enum LabDataKind {
	Collection = 'Collection',
	Single = 'Single',
	Comment = 'Comment',
	Document = 'Document',
}

export function isCollectionKindLabResult(
	navinaLabResult: NavinaLabResult,
): navinaLabResult is NavinaLabResult & { data: Record<string, LabDataType> } {
	return navinaLabResult.type === LabDataKind.Collection;
}

export function isSingleKindLabResult(
	navinaLabResult: NavinaLabResult,
): navinaLabResult is NavinaLabResult & { data: LabDataType } {
	return navinaLabResult.type === LabDataKind.Single;
}

export function isCommentLabResult(
	navinaLabResult: NavinaLabResult,
): navinaLabResult is NavinaLabResult & { data: 'normal' } {
	return navinaLabResult.type === LabDataKind.Comment;
}

export interface NavinaLabResult {
	readonly data: Record<string, LabDataType> | 'normal' | LabDataType;
	readonly date?: string;
	readonly name: string;
	readonly type: LabDataKind;
	readonly child: NavinaLabResult;
	readonly docName?: string;
	readonly docId?: string;
	readonly displayName?: string;
	readonly source?: LabSource;
	readonly sourceDetails?: string;
	readonly originalValue?: number;
	readonly observations?: { readonly [key: string]: Observation };
}

export enum LabSource {
	HIE = 'HIE',
	OCR = 'OCR',
	REDOX_EVENT = 'redox-event',
}

export enum DocSourceType {
	HIE = 'HIE',
	EHR = 'EHR',
}

interface TrendLab {
	readonly date: string;
	readonly observationValue: null | string;
	readonly originalValue: null | string;
	readonly observationNote: null | string;
	readonly source: LabSource;
	readonly sourceDetails: null | string;
}

export interface TrendViewLab {
	readonly annotationLineGroups: ReadonlyArray<AnnotationLineGroup>;
	readonly label: string;
	readonly yAxisMin?: number;
	readonly yAxisMax?: number;
	readonly maxThresholdLine?: number;
	readonly minThresholdLine?: number;
	readonly units: string;
	readonly history: ReadonlyArray<TrendLab>;
}

export interface ImmunizationReccomendation {
	readonly name: string;
	readonly reason: string;
}

export interface MedicalAlert {
	readonly name: string;
	readonly text: string;
	readonly reason: string;
}

export interface StatusProps {
	readonly crawlerStatus?: boolean;
	readonly etlStatus?: boolean;
	readonly automatedSummaryStatus?: boolean;
	readonly modified?: Date;
}

export interface DisplayDoc {
	readonly name: string;
	readonly primaryName?: string;
	secondaryName?: string; // TODO - make readonly
	readonly predictionCodes: ReadonlyArray<string>;
	readonly date: string;
	readonly emrInsertionDate: string;
	readonly clinicalDocDate: string;
	readonly id: string;
	readonly loinc?: string;
	readonly classifiedLabel?: string;
	readonly classifiedLoinc?: string;
	readonly classifierTags?: ReadonlyArray<string>;
	readonly classifierName?: string;
	readonly codeOrigin?: string;
	icdsData?: ReadonlyArray<DocIcdData>; // TODO - make readonly
	readonly source: DocSource;
	textHighlight?: ReadonlyArray<string>; // TODO - make readonly
	primaryNameHighlight?: string; // TODO - make readonly
	secondaryNameHighlight?: string; // TODO - make readonly
	icdDescriptionHighlights?: Array<string>; // TODO - make readonly AND ReadonlyArray
	icdCodeHighlights?: Array<string>; // TODO - make readonly AND ReadonlyArray
	readonly impression?: string;
	readonly hash?: string;
	readonly fileUrlPayload?: FileUrlPayload;
}

export interface DocIcdData {
	readonly description: string;
	readonly icd10Code: string;
	readonly icd10CodeLineContent: string;
	readonly icd10CodeLine: number;
	readonly snomed?: string;
	hccs: HCCs; // TODO - make readonly
	readonly hccWeight: number;
	readonly hccGroup: string;
	readonly docId: string;
}

export interface DocSource {
	readonly name: string;
	readonly type: string;
}

export interface DiagnosisOverviewHieSource {
	readonly fileName: string;
	readonly source: DocSource;
}

export interface DocsByDISystemGroup {
	readonly [type: string]: Array<DisplayDoc>; // TODO - make ReadonlyArray
}

export interface Medication {
	readonly name: string;
	readonly dosage: string;
	readonly frequency: string;
	readonly medicationDate: string;
	readonly daysLeft: number | null;
	readonly atcLevel2?: string;
	readonly atcLevel5?: string;
	readonly displayInMedicationsList?: boolean;
	readonly rxcui?: string;
}

export interface PreventivesType {
	readonly bmi: unknown;
	readonly bp: unknown;
	readonly breastCancerScreening: unknown;
	readonly pap: unknown;
	readonly boneDensity: unknown;
	readonly ascvdData: {
		readonly value?: number;
		readonly missingElements?: ReadonlyArray<string>;
		readonly outOfRangeElements?: ReadonlyArray<string>;
		readonly status?: 'success' | 'age_failure' | 'dx_failure' | 'other_failure';
	};
	// Deprecated, kept for backward compatibility of old summaries
	readonly colonoscopy: {
		readonly value: { procedure?: string; pathology?: string; note?: string };
		readonly date?: any;
		readonly isFromSurgicalHistory: boolean;
		readonly source?: string;
	};
	readonly colonCancerScreening: {
		readonly value: { procedure?: string; pathology?: string; note?: string } | string;
		readonly date?: any;
		readonly isFromSurgicalHistory?: boolean;
		readonly isColonoscopy?: boolean;
		readonly source?: string;
	};
}

export interface PatientData extends StatusProps {
	readonly canSeePush2Emr: boolean;
	readonly timezone: string;
	readonly clinicName: string;
	readonly allDocs: Array<DisplayDoc>; // TODO - make ReadonlyArray
	readonly icdsFromDocuments?: ReadonlyArray<DocIcdData>;
	readonly imagingAndTests: DocsByDISystemGroup;
	readonly modified: Date;
	readonly providerName: string;
	readonly emrName: emrNames;
	readonly id: string;
	readonly sid: string;
	readonly nid: string;
	readonly date: string;
	readonly startTime: string;
	readonly startTimeUnix: number;
	readonly endTimeUnix: number;
	readonly reasonForVisit: string;
	readonly allergies: ReadonlyArray<string>;
	readonly demographics: DemographicsType;
	readonly raf?: RafType;
	readonly previousEncounter: PreviousEncounter;
	readonly lastWellnessEncounter?: PreviousEncounter;
	readonly socialHistory: {
		readonly isSmoker: boolean;
		readonly isFormerSmoker: boolean;
		readonly alcoholScreening?: boolean;
		readonly numberOfChildren?: number;
	};
	readonly preventive: PreventivesType;
	readonly problemList: Array<ProblemType>; // TODO - make ReadonlyArray
	readonly problemListRaw: ReadonlyArray<string> | ReadonlyArray<SnomedCodeAndDescription>;
	readonly medications: ReadonlyArray<Medication>;
	readonly immunizationsRecommendations: ReadonlyArray<ImmunizationReccomendation>;
	readonly labs: ReadonlyArray<NavinaLabResult>;
	readonly labsToShow: Array<NavinaLabResult>; // TODO - make ReadonlyArray
	readonly familyHistory: any;
	readonly surgicalHistory: ReadonlyArray<SurgicalHistoryEntry> | null;
	readonly missingDiagnoses: MissingDiagnosesType;
	readonly agilonNonDxSuggestions: ReadonlyArray<AgilonNonDxType>;
	readonly hospitalization: ReadonlyArray<NoteType>;
	readonly medicalAlerts: ReadonlyArray<MedicalAlert>;
	readonly visitType: string;
	readonly dataSourceUrl?: string;
	readonly dataSourceId?: number;
	readonly emrDepartmentId: string;
	readonly emrAppointmentId: string;
	readonly summaryInsightStatus: Array<SummaryInsightStatus>; // TODO - make ReadonlyArray
	readonly summaryInsightActions: ReadonlyArray<SummaryInsightAction>;
	readonly summaryInsightNotes: ReadonlyArray<SummaryInsightNote>;
	readonly summaryInsightAgilonNonDxActions: Array<AgilonNonDxStatusDataType>; // TODO - make ReadonlyArray
	readonly lastPostVisitTime: string;
	readonly lastScrubTime: string;
	readonly lastVisitScrubTime: string;
	readonly scrubberName: string;
	readonly pushToEmrId?: string;
	readonly pushContext: PushContext;
	readonly summarySuggestions: SuggestedDiagnosis[][];
	readonly nonRelevantDxs: Array<string>; // TODO - make ReadonlyArray
}

interface SummaryInsightNote {
	readonly id: string;
	readonly icdCode: string;
	readonly sid: string;
	readonly note: string;
	readonly updatedBy: string;
	readonly updatedByFullName: string;
	readonly role: 'provider' | 'coder';
	readonly updatedAt: string;
	readonly shouldPush: boolean;
	readonly noteDate: string;
	readonly insertionDate: string;
	readonly addedInPostVisit: boolean;
}

export interface SummaryInsightStatus {
	readonly id: string;
	readonly navinaUserId: string;
	readonly sid: string;
	readonly status: string;
	readonly type: string;
}

interface SummaryInsightAction {
	readonly id: string;
	readonly sid: string;
	readonly snomedCode: string;
	readonly icdCode: string;
	readonly metadata: PushToEmrDiagnosis;
	readonly status: PushToEmrDiagnosisStatus;
	readonly type: PushToEmrDiagnosisSourceType;
	readonly updatedAt: string;
	readonly updatedBy: string;
}

export interface SummaryRecord extends StatusProps {
	readonly id: string;
	readonly nid: string;
	readonly date: string;
	readonly startTime: string;
	readonly endTime: number;
	readonly startTimeUnix: number;
	readonly endTimeUnix: number;
	readonly reasonForVisit: string;
	readonly patientEmrId: string;
	readonly previousEncounter?: PreviousEncounter;
	readonly modified: Date;
	readonly timezone: string;
	readonly clinicName: string;
	readonly demographics?: DemographicsType;
	readonly providerName: string;
	readonly visitType: string;
	readonly summaryStatusErrorMessage: string;
	readonly lastScrubTime: string;
}

export interface ProblemDocType {
	readonly id: string;
	readonly loinc: string;
	readonly name: string;
	readonly primaryName: string;
	readonly secondaryName: string;
	readonly date?: string;
}

export interface SurgicalHistoryEntry {
	surgeryName: string; // TODO - make readonly
	readonly surgeryDate: string;
	readonly freeText: string;
	readonly addedToEmrDate: string;
	readonly addedToEmrBy: string;
}

export interface NoteType {
	readonly name: string;
	readonly docName: string;
	readonly id: string;
	readonly date: string;
	readonly description?: string;
	readonly source?: string;
	readonly hash?: string;
}

export interface FileUrlPayload {
	readonly url: string;
	readonly filename: string;
	readonly docSource: DocSource;
}

export interface OpenSearchHit {
	readonly highlight: {
		readonly text?: ReadonlyArray<string>;
		readonly primaryName?: ReadonlyArray<string>;
		readonly secondaryName?: ReadonlyArray<string>;
		readonly icdsDataDescription?: Array<string>; // TODO - make ReadonlyArray
		readonly icdsDataIcd10Code?: Array<string>; // TODO - make ReadonlyArray
	};
	readonly id: string;
	readonly index: string;
	readonly score: number;
	readonly source: {
		readonly codeOrigin: string;
		readonly date: string;
		readonly emrFileId: string;
		readonly emrName: string;
		readonly emrPatientId: string;
		readonly icdsData: any;
		readonly id: string;
		readonly indexingDate: string;
		readonly loinc: string;
		readonly metadata: string;
		readonly name: string;
		readonly nid: string;
		readonly numOfPages: string;
		readonly predictionCodes: ReadonlyArray<string>;
		readonly primaryName: string;
		readonly secondaryName: string;
		readonly text: string;
	};
	readonly type: string;
}

export type emrNames = 'ecw' | 'athena' | 'epic' | 'veradigm';
export type TabsViewTabName = 'hcc' | 'portrait' | 'documents' | 'alerts' | 'quality';

export interface ScheduleFilter {
	readonly selectedClinicId: number;
	readonly selectedDepartmentId: number;
	readonly selectedProviderIds: ReadonlyArray<number>;
	readonly selectedInsuranceFilterGroups: ReadonlyArray<number>;
}

interface QuicksightEmbedSettingsBasedOnDashboards {
	readonly dashboardNames: DashboardNamesType[];
	readonly clinics?: never;
	readonly hccVersion?: never;
}

interface QuicksightEmbedSettingsBasedOnClinics {
	readonly dashboardNames?: never;
	readonly clinics: Record<number, string>;
	readonly hccVersion: HccVersions;
}

export type QuicksightEmbedSettings = QuicksightEmbedSettingsBasedOnDashboards | QuicksightEmbedSettingsBasedOnClinics;

export interface UserClinicsHierarchyType {
	readonly clinics: Array<{ id: number; name: string; parentId: number }>; // TODO - make ReadonlyArray
	readonly markets: Array<{ id: number; name: string; parentId: number }>; // TODO - make ReadonlyArray
	readonly organizations: Array<{ id: number; name: string }>; // TODO - make ReadonlyArray
}

export type TabId = 'dxInsights' | 'clinicalSummary' | 'clinicalDocuments' | 'clinicalAlerts';

export interface EmrOnlineSyncResponse {
	readonly icd10Codes: Array<DxFromEmr>; // TODO - make ReadonlyArray
}

export enum identityProviders {
	Auth0 = 'auth0',
	Navnia = 'navina',
}

export interface IcdDataDB {
	readonly description: string;
	readonly icdcode: string;
	readonly hccs: HCCs;
}

export type PickOptional<T extends Object> = { [Key in keyof T as {} extends Pick<T, Key> ? Key : never]-?: T[Key] };

export type LocalStorageData = {
	date: number;
	diagnosesInCart: DxDict;
	updatedSummarySuggestions: SuggestedDiagnosis[][];
	originalSummarySuggestions: SuggestedDiagnosis[][];
	actionsDifferences: DiagnosisActionStatus[];
};

export enum DxNotificationStatus {
	Saved = 'Saved',
	UnsavedChanges = 'UnsavedChanges',
	None = 'None',
	StopShowing = 'StopShowing',
}

export type DxNotificationStatusProps = { type: NotificationType } & Pick<
	ArgsProps,
	'duration' | 'onClose' | 'message'
>;
