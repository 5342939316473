import { CoreText } from '../../base-ui/CoreText';
import { FullLogo } from '../../base-ui/graphics/FullLogo';
import AuthBackground from '../../base-ui/graphics/auth/AuthBackground.svg';
import DecorationIllustration from '../../base-ui/graphics/auth/DecorationIllustration.svg';
import { type NavinaLayout } from './LayoutType';
import { keyframes } from '@emotion/react/macro';
import styled from '@emotion/styled/macro';
import { Box } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { Suspense } from 'react';

const fadeIn = keyframes`
	from { opacity: 0; }
	to { opacity: 1; }
`;

const PageContainer = styled.div`
	inline-size: 100%;
	block-size: 100%;
	overflow: hidden;

	background: url('${AuthBackground}') no-repeat center center fixed;
	background-size: cover;

	display: flex;
	align-items: center;
	justify-content: center;
`;

const ContentContainer = styled.div`
	overflow: hidden;
	background: rgba(255, 255, 255, 0.7);
	box-shadow: 0 10px 40px rgba(0, 0, 0, 0.3);
	border-radius: 8px;

	inline-size: 800px;
	max-inline-size: 90%;

	@media (width >= 601px) {
		min-block-size: 500px;
	}

	block-size: 500px;

	display: flex;
	flex-wrap: nowrap;

	animation: ${fadeIn} 0.3s ease-in;

	& > * {
		flex: 1 1 0;
		display: flex;
		block-size: 100%;
	}

	flex-direction: row;

	@media (width <= 768px) {
		flex-direction: column-reverse;
	}
`;

const FormBlock = styled.div`
	text-align: center;
	inline-size: 100%;
	padding: 20px;
	word-break: break-word;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow-x: hidden;

	> * {
		max-inline-size: 100%;
	}

	flex-direction: column;
`;

const DecorationBlock = styled.div`
	padding: 0;
	background: #322b5f;
	background-image: url('${DecorationIllustration}');
	background-repeat: no-repeat;
	background-size: cover;

	@media (width <= 768px) {
		max-block-size: 15%;
	}
`;

const LogoWrapper = styled.div`
	margin-block-end: 30px;
`;

interface AuthLayoutProps extends NavinaLayout {}

export const AuthLayout = observer(function AuthLayout({ component: Component, ...props }: AuthLayoutProps) {
	return (
		<PageContainer>
			<ContentContainer>
				<Box position='relative'>
					<FormBlock>
						<LogoWrapper>
							<FullLogo height={28} />
						</LogoWrapper>
						<Suspense fallback={<div />}>
							<Component {...props} />
						</Suspense>
					</FormBlock>
					{localStorage.getItem('ssoUrl') && (
						<Box position='absolute' marginBottom={3} bottom={0} width='100%' textAlign='center'>
							<CoreText type='body2'>
								Don't have an account?{' '}
								<CoreText type='body2' color='accentGreen' cursor='pointer'>
									<a href='mailto:support@navina.ai' target='_blank' rel='noopener noreferrer'>
										Contact support
									</a>
								</CoreText>
							</CoreText>
						</Box>
					)}
				</Box>
				<DecorationBlock />
			</ContentContainer>
		</PageContainer>
	);
});
