import { colors } from '../../base-ui/Colors';
import { CoreContainer } from '../../base-ui/CoreContainer';
import { MAX_WIDTH_LOWER_WIDTH } from '../../base-ui/Resolutions';
import styled from '@emotion/styled/macro';

export const PageContainer = styled.div<{ readonly $isOverlayInTabsView: boolean }>`
	block-size: 100%;
	inline-size: 100%;
	overflow: hidden;

	grid-template-columns: 108px ${(props) =>
			props.$isOverlayInTabsView ? 'minmax(0, 1fr) 88px' : 'minmax(0, 8.3fr) 4.3fr'};
	grid-template-rows: 95px min-content 54px auto;
	background-color: ${colors.background};

	@media ${MAX_WIDTH_LOWER_WIDTH} {
		grid-template-rows: 90px min-content 54px auto;
		grid-template-columns: 108px ${(props) =>
				props.$isOverlayInTabsView ? 'minmax(0, 1fr) 88px' : 'minmax(0, 7.6fr) 5fr'};
	}

	${(props) =>
		props.$isOverlayInTabsView &&
		`
			display: flex;
			flex-direction: column;
    `}
`;

export const BackgroundCell = styled.div<{ readonly $column: number | string; readonly $row: number | string }>`
	background-color: ${colors.primary};
	grid-column: ${(props) => props.$column};
	grid-row: ${(props) => props.$row};
`;

export const SummaryFeaturesContainer = styled.div<{ readonly $isOverlayInTabsView: boolean }>`
	background-color: ${colors.background};
	border-radius: ${(props) => (props.$isOverlayInTabsView ? '0' : '8px 8px 0 0')};
	grid-column: ${(props) => (props.$isOverlayInTabsView ? '1 / -1' : '2')};
	grid-row: 3 / span 2;
	box-shadow: 0 10px 15px -3px rgba(50, 43, 95, 0.5);
	display: flex;
	flex-direction: column;
	padding-block: 10px 15px;
	padding-inline: 30px 50px;
	margin-inline-start: ${(props) => (props.$isOverlayInTabsView ? '0' : '7px')};
	overflow-y: auto;
	scroll-behavior: smooth;
	position: relative;
	block-size: 100%;

	@media ${MAX_WIDTH_LOWER_WIDTH} {
		padding: 0;
	}
`;

export const DiagnosesOverviewContainer = styled(CoreContainer)`
	margin: 10px;
	padding: 30px;

	@media ${MAX_WIDTH_LOWER_WIDTH} {
		padding-block: 10px;
		padding-inline: 20px;
	}
`;

export const EstimatedRafContainer = styled(CoreContainer)<{ readonly $isOverlay: boolean }>`
	inline-size: 100%;
	padding: ${(props) => (props.$isOverlay ? '0 0 0 20px' : '20px 10px 20px 20px')};
	block-size: 20%;

	@media ${MAX_WIDTH_LOWER_WIDTH} {
		padding-block: 10px;
		padding-inline: 20px;
	}

	border-radius: 8px;
`;

export const SummaryRightSideContainer = styled.div`
	grid-column: 3;
	grid-row: 4;
	display: flex;
	flex-direction: column;
	padding-block: 0 40px;
	padding-inline: 40px 35px;
	overflow-y: auto;

	@media ${MAX_WIDTH_LOWER_WIDTH} {
		padding-block: 0 40px;
		padding-inline: 20px 15px;
	}
`;

export const LogoContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-inline-end: 14px;
`;
