import { notification } from 'antd';
import { type NotificationInstance } from 'antd/lib/notification/interface';
import React, { createContext, type PropsWithChildren, useContext, useMemo } from 'react';

export type NotificationType = 'success' | 'info' | 'warning' | 'error';

type NotificationConsumerProps = {
	notificationApi: NotificationInstance;
};

const NotificationContext = createContext({} as NotificationConsumerProps);

export const NotificationProvider: React.FC<PropsWithChildren> = ({ children }) => {
	const [api, contextHolder] = notification.useNotification({ duration: 0, placement: 'top', maxCount: 1 });

	const notificationValue = useMemo<NotificationConsumerProps>(() => ({ notificationApi: api }), []);

	return (
		<NotificationContext.Provider value={notificationValue}>
			{contextHolder}
			{children}
		</NotificationContext.Provider>
	);
};

export const useNotificationApi = () => {
	return useContext(NotificationContext);
};
