import { type SVGProps } from 'react';

export function UserIcon(props: SVGProps<SVGSVGElement>) {
	return (
		<svg
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			stroke='currentColor'
			{...props}
		>
			<circle r='2.25' transform='matrix(-1 0 0 1 12 8)' strokeWidth='1.5' />
			<path d='M6 19C6 15.6863 8.68629 13 12 13C15.3137 13 18 15.6863 18 19' strokeWidth='1.5' />
		</svg>
	);
}
