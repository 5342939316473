export const withPhi = {
	'data-phi': true,
} as const;

export const withHideActionFromViewOnly = {
	'data-actionable': true,
} as const;

export const withDisabledSnapshot = {
	chromatic: { disableSnapshot: true },
} as const;
