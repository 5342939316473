/* eslint-disable @typescript-eslint/no-restricted-imports -- Importing dayjs is allowed only here, by design. */
import dayjs, { extend as extendDayJsWithPlugin, type ConfigType, type Dayjs } from 'dayjs';
import customParseFormatPlugin from 'dayjs/plugin/customParseFormat';
import isoWeekPlugin from 'dayjs/plugin/isoWeek';
import relativeTimePlugin from 'dayjs/plugin/relativeTime';
import timezonePlugin from 'dayjs/plugin/timezone';
import utcPlugin from 'dayjs/plugin/utc';

// import isBetweenPlugin from 'dayjs/plugin/isBetween';
/* eslint-enable */

extendDayJsWithPlugin(utcPlugin);
extendDayJsWithPlugin(timezonePlugin);
extendDayJsWithPlugin(isoWeekPlugin);
extendDayJsWithPlugin(relativeTimePlugin);
extendDayJsWithPlugin(customParseFormatPlugin);
// extendDayJsWithPlugin(isBetweenPlugin);

export type DayJsInput = ConfigType;

export interface MaybeWithLocale {
	readonly locale?: string | undefined;
}

interface WithDate {
	readonly date: DayJsInput;
}

export interface DateFormattingArgs extends WithDate {
	readonly now?: DayJsInput;
}

export interface IsDateInRangeParam extends WithDate {
	readonly relativeDate?: DayJsInput;
}

type Digit = `${0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9}`;
type DigitWithoutZero = Exclude<Digit, '0'>;

type YY = `${Digit}${Digit}`; // Year 00-99
type YYYY = `${19 | 20}${Digit}${Digit}`; // Year 1900-2099

type OneToNine = `0${DigitWithoutZero}`;
type ZeroToNine = `0${Digit}`;

type MM = `${OneToNine}` | `1${0 | 1 | 2}`; // Month 01-12
type DD = `${OneToNine}` | `${1 | 2}${Digit}` | `3${0 | 1}`; // Day 01-31

type ShortWeekday = 'Sun' | 'Mon' | 'Tue' | 'Wed' | 'Thu' | 'Fri' | 'Sat';

type hh = `${ZeroToNine}` | `1${0 | 1 | 2}`; // 12-hour format (1-12)
// type HH = `${ZeroToNine}` | `1${0 | 1 | 2 | 3 | 4 | 5}` | `2${0 | 1 | 2 | 3}`; // 24-hour format (0-23)

type mm = `${ZeroToNine}` | `${1 | 2 | 3 | 4 | 5}${Digit}`; // Minutes 00-59

type TimePeriod = 'AM' | 'PM';
type TimeFormat = `${hh}:${mm} ${TimePeriod}`;

export type Iso8601DateFormat = `${YYYY}-${MM}-${DD}`;
export type Iso8601TimeFormat = `${number}:${number}:${number}`;
export type Iso8601DateTimeFormat = `${Iso8601DateFormat}T${Iso8601TimeFormat}`;
export type Iso8601DateTimeWithTimezoneFormat = `${Iso8601DateTimeFormat}Z`;

type NavinaDateFormatWithoutYear = `${MM}/${DD}`;
type NavinaShortYearDateFormat = `${NavinaDateFormatWithoutYear}/${YY}`;
type NavinaDateFormat = `${NavinaDateFormatWithoutYear}/${YYYY}`;
type NavinaDateFormatWithoutYearWithDay = `${ShortWeekday} ${MM}/${DD}`;

type TimeToDate =
	| 'a few seconds ago'
	| 'a minute ago'
	| `${number} minutes ago`
	| 'an hour ago'
	| `${number} hours ago`
	| 'a day ago'
	| `${number} days ago`
	| 'a month ago'
	| `${number} months ago`
	| 'a year ago'
	| `${number} years ago`;

export const ISO_8601_DATE_FORMAT = 'YYYY-MM-DD';
export const ISO_8601_DATE_TIME_FORMAT = `${ISO_8601_DATE_FORMAT}THH:mm:ss`;
export const ISO_8601_DATE_TIME_FORMAT_WITH_TIMEZONE = `${ISO_8601_DATE_TIME_FORMAT}Z`;

export const NAVINA_DATE_FORMAT_WITHOUT_YEAR = 'MM/DD';
export const NAVINA_DATE_FORMAT_SHORT_YEAR = 'MM/DD/YY';
export const NAVINA_DATE_FORMAT = 'MM/DD/YYYY';
export const NAVINA_DATE_FORMAT_WITHOUT_YEAR_WITH_DAY = 'ddd MM/DD';
export const NAVINA_TIME_FORMAT = 'hh:mm A';

const IS_STORYBOOK = process.env.STORYBOOK === 'true';

export const STORYBOOK_DATE: Iso8601DateFormat = '2022-11-11';
const STORYBOOK_TIME: Iso8601TimeFormat = '12:15:00';
const STORYBOOK_DATE_TIME: Iso8601DateTimeWithTimezoneFormat = `${STORYBOOK_DATE}T${STORYBOOK_TIME}Z`;

const getNowWithStorybookOverride = (): Date => (IS_STORYBOOK ? new Date(STORYBOOK_DATE_TIME) : new Date());
const getNow = (): Dayjs => dayjs(getNowWithStorybookOverride());

export function getNowDate(): Date {
	return getNow().toDate();
}

export const getFormattedNow = (): string => dayjs(getNowWithStorybookOverride()).format(ISO_8601_DATE_TIME_FORMAT);

// 2024-04-27T14:30:00Z				- ISO 8601 as UTC (because of the Z at the end)
// 2024-04-27T14:30:00+02:00	- ISO 8601 with timezone offset (2 hours ahead of UTC)
// 2024-04-27T14:30:00 				- ISO 8601 without timezone offset (local time)
export function getFormattedIso8601Date({
	date,
	shouldUseUTC = false,
}: WithDate & { readonly shouldUseUTC?: boolean }): Iso8601DateFormat {
	const dateToUse = shouldUseUTC ? dayjs(date).utc() : dayjs(date);
	return dateToUse.format(ISO_8601_DATE_FORMAT) as Iso8601DateFormat;
}

export function getFormattedIso8601DateTime({
	date,
	shouldUseUTC = false,
}: WithDate & { readonly shouldUseUTC?: boolean }): Iso8601DateTimeFormat {
	const dateToUse = shouldUseUTC ? dayjs(date).utc() : dayjs(date);
	return dateToUse.format(ISO_8601_DATE_TIME_FORMAT) as Iso8601DateTimeFormat;
}

export function getFormattedIso8601DateTimeWithTimezone({ date }: WithDate): Iso8601DateTimeWithTimezoneFormat {
	return dayjs(date).toISOString() as Iso8601DateTimeWithTimezoneFormat;
}

export function getFormattedDateWithoutYear({
	date,
	shouldUseUTC = false,
}: WithDate & { readonly shouldUseUTC?: boolean }): NavinaDateFormatWithoutYear {
	const dateToUse = shouldUseUTC ? dayjs(date).utc() : dayjs(date);
	return dateToUse.format(NAVINA_DATE_FORMAT_WITHOUT_YEAR) as NavinaDateFormatWithoutYear;
}

export function getFormattedDateShortYear({
	date,
	shouldUseUTC = false,
}: WithDate & { readonly shouldUseUTC?: boolean }): NavinaShortYearDateFormat {
	const dateToUse = shouldUseUTC ? dayjs(date).utc() : dayjs(date);
	return dateToUse.format(NAVINA_DATE_FORMAT_SHORT_YEAR) as NavinaShortYearDateFormat;
}

export function getCustomFormattedDate<T = string>({
	date,
	format,
	shouldUseUTC = false,
}: WithDate & { readonly format: string; readonly shouldUseUTC?: boolean }): T {
	const dateToUse = shouldUseUTC ? dayjs(date).utc() : dayjs(date);
	return dateToUse.format(format) as T;
}

export function getFormattedDate({
	date,
	shouldUseUTC = false,
}: WithDate & { readonly shouldUseUTC?: boolean }): NavinaDateFormat {
	const dateToUse = shouldUseUTC ? dayjs(date).utc() : dayjs(date);
	return dateToUse.format(NAVINA_DATE_FORMAT) as NavinaDateFormat;
}

export function getFormattedDateWithoutYearWithDay({
	date,
	shouldUseUTC = false,
}: WithDate & { readonly shouldUseUTC?: boolean }): NavinaDateFormatWithoutYearWithDay {
	const dateToUse = shouldUseUTC ? dayjs(date).utc() : dayjs(date);
	return dateToUse.format(NAVINA_DATE_FORMAT_WITHOUT_YEAR_WITH_DAY) as NavinaDateFormatWithoutYearWithDay;
}

export function getFormattedTime({ date }: WithDate): TimeFormat {
	return dayjs(date).format(NAVINA_TIME_FORMAT) as TimeFormat;
}

export function getFormattedCognitoDate({
	date,
	shouldUseUTC = false,
}: WithDate & { readonly shouldUseUTC?: boolean }): string {
	const dateToUse = shouldUseUTC ? dayjs(date).utc() : dayjs(date);
	return dateToUse.format('YYYY-MM-DD hh:mm:ss [UTC]');
}

export function format24HourTimeTo12HourTime({
	time: inputTime,
	timeFormat: inputTimeFormat = 'HH:mm:ss',
}: {
	readonly time: string;
	readonly timeFormat?: string;
}): TimeFormat {
	const parsedTime = dayjs(inputTime, inputTimeFormat, true);

	if (!parsedTime.isValid()) {
		throw Error(`Invalid time format: ${inputTime}`);
	}

	return parsedTime.format(NAVINA_TIME_FORMAT) as TimeFormat;
}

export function getDateFromIso8601Format(date: string): Date {
	return dayjs(date).toDate();
}

export function getWeekdaysOfDate({
	date,
	isoWeek = true,
}: WithDate & { readonly isoWeek?: boolean }): ReadonlyArray<Dayjs> {
	const startOfWeek = dayjs(isoWeek ? getStartOfIsoWeek(date) : getStartOfWeek(date));

	return Array.from({ length: 7 }, (_, index) => startOfWeek.add(index, 'day'));
}

export function addNWeeksToDate({
	date,
	incrementWeeks,
}: {
	readonly date: DayJsInput;
	readonly incrementWeeks: number;
}): Date {
	return dayjs(date).add(incrementWeeks, 'week').toDate();
}

export const add1WeekToDate = (date: DayJsInput): Date => addNWeeksToDate({ date, incrementWeeks: 1 });
export const add2WeeksToDate = (date: DayJsInput): Date => addNWeeksToDate({ date, incrementWeeks: 2 });

export function addNDaysToDate({
	date,
	incrementDays,
}: {
	readonly date: DayJsInput;
	readonly incrementDays: number;
}): Date {
	return dayjs(date).add(incrementDays, 'day').toDate();
}

export function addNMonthsToDate({
	date,
	incrementMonths,
}: {
	readonly date: DayJsInput;
	readonly incrementMonths: number;
}): Date {
	return dayjs(date).add(incrementMonths, 'month').toDate();
}

export function addNYearsToDate({
	date,
	incrementYears,
}: {
	readonly date: DayJsInput;
	readonly incrementYears: number;
}): Date {
	return dayjs(date).add(incrementYears, 'year').toDate();
}

export function createDayjsForAntdDatePicker({ date }: WithDate): Dayjs {
	return dayjs(date);
}

export const compareDates = (dateA: DayJsInput, dateB: DayJsInput): -1 | 0 | 1 => {
	const [timestampA = 0, timestampB = 0] = [dateA, dateB].map((date) => dayjs(date).unix());
	if (timestampA === timestampB) {
		return 0;
	}
	return timestampA > timestampB ? 1 : -1;
};

export function getStartOfWeek(date: DayJsInput = getNow()): Date {
	return dayjs(date).startOf('week').toDate();
}

export function getStartOfIsoWeek(date: DayJsInput = getNow()): Date {
	return dayjs(date).startOf('isoWeek').toDate();
}

export function getStartOfDay(date: DayJsInput = getNow()): Date {
	return dayjs(date).startOf('day').toDate();
}

export function getTimeToDate({
	date,
	now = getFormattedNow(),
	roundDays = false,
}: DateFormattingArgs & { readonly roundDays?: boolean }): TimeToDate {
	if (roundDays) {
		return dayjs(getStartOfDay()).to(getStartOfDay(date)) as TimeToDate;
	}
	return dayjs(now).to(date) as TimeToDate;
}

export function getShortTimeToDate({ date, now = getFormattedNow() }: DateFormattingArgs): string {
	return getTimeToDate({ date, now }).replace('hours', 'hrs').replace('minutes', 'mins');
}

export const isDateInPast = ({ date, relativeDate = getFormattedNow() }: IsDateInRangeParam): boolean =>
	dayjs(relativeDate).isAfter(date);

export const isDateInFuture = ({ date, relativeDate = getFormattedNow() }: IsDateInRangeParam): boolean =>
	dayjs(relativeDate).isBefore(date);

export const isDateToday = ({ date, relativeDate = getFormattedNow() }: IsDateInRangeParam): boolean =>
	dayjs(relativeDate).isSame(date, 'day');

export function getNowUnix(): number {
	return getNow().unix();
}

export function getUnixDate(date: DayJsInput): number {
	return dayjs(date).unix();
}

export function getTimeInTimezone({ date, timezone }: WithDate & { readonly timezone: string }): Date {
	return dayjs(date).utc().tz(timezone).toDate();
}

export function getDateFromUnixTime({
	timeUnix = getNowUnix(),
	timezone,
}: {
	readonly timeUnix?: number;
	readonly timezone: string;
}): Date {
	const time = dayjs.unix(timeUnix);
	return getTimeInTimezone({ date: time, timezone });
}

export function getYearFromDate(date: DayJsInput): number {
	return dayjs(date).year();
}

type ZeroIndexedMonth = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;
export function getZeroIndexedMonthFromDate(date: DayJsInput): ZeroIndexedMonth {
	return dayjs(date).month() as ZeroIndexedMonth;
}

type OneToTwelve = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
export function getMonthFromDate(date: DayJsInput): OneToTwelve {
	return (dayjs(date).month() + 1) as OneToTwelve;
}

type OneToThirtyOne =
	| 1
	| 2
	| 3
	| 4
	| 5
	| 6
	| 7
	| 8
	| 9
	| 10
	| 11
	| 12
	| 13
	| 14
	| 15
	| 16
	| 17
	| 18
	| 19
	| 20
	| 21
	| 22
	| 23
	| 24
	| 25
	| 26
	| 27
	| 28
	| 29
	| 30
	| 31;
export function getDayFromDate(date: DayJsInput): OneToThirtyOne {
	return dayjs(date).date() as OneToThirtyOne;
}

export function getGuessedTimezone(): string {
	return dayjs.tz.guess();
}

/**
 * Returns the difference in days between two dates.
 * @param date - The date to compare against.
 * @param relativeDate - The date to compare with.
 * @returns The difference in days.
 * @example
 * getDaysDifferenceBetweenDates({ date: '2022-12-20', relativeDate: '2022-12-22' });
 * // Returns -2
 * @example
 * getDaysDifferenceBetweenDates({ date: '2022-12-20', relativeDate: '2022-12-18' });
 * // Returns 2
 * @example
 * getDaysDifferenceBetweenDates({ date: '2022-12-20', relativeDate: '2022-12-20' });
 * // Returns 0
 *
 **/
export function getDaysDifferenceBetweenDates({ date, relativeDate = getFormattedNow() }: IsDateInRangeParam): number {
	return dayjs(relativeDate).diff(date, 'days');
}
