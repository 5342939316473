import { getThemeStringValueByName } from '../../utilities/styling/UseCssVarByName';
import { getOrCreateAnalytics } from '../../utils/analytics';
import styled from '@emotion/styled/macro';
import { Typography, theme } from 'antd';
import { observer } from 'mobx-react-lite';
import type { ReactNode } from 'react';

const IconContainer = styled.div<{ readonly $isSelected: boolean }>`
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 4px;
	min-inline-size: 70px;
	max-inline-size: 70px;
	min-block-size: 70px;
	max-block-size: 70px;
	margin-inline-end: 14px;
	background-color: ${(props) => getThemeStringValueByName(props.$isSelected ? 'purple.1' : 'colorFillSecondary')};
	border: ${(props) => (props.$isSelected ? `1px solid ${getThemeStringValueByName('purple.2')}` : 'none')};
`;

const SelectionIndicator = styled.div<{ readonly $isSelected: boolean }>`
	max-inline-size: 3px;
	min-inline-size: 3px;
	block-size: 70px;
	border-start-end-radius: 5px;
	border-end-end-radius: 5px;
	margin-inline-end: 5px;
	background-color: ${(props) => (props.$isSelected ? getThemeStringValueByName('colorPrimary') : 'transparent')};
`;

const TextContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

const HoverContainer = styled.div`
	display: inline-flex;
	align-items: center;
	justify-content: center;
	margin-block: 8px;
	margin-inline: 0 8px;
	border-radius: 6px;
	padding: 7px;
`;

const Container = styled.a`
	display: inline-flex;
	align-items: center;
	justify-content: center;

	&:hover {
		${HoverContainer} {
			background-color: ${getThemeStringValueByName('controlItemBgHover')};
		}
	}
`;

interface PopoverItemProps {
	readonly title: string;
	readonly description: string;
	readonly link: string;
	readonly icon: ReactNode;
	readonly isSelected: boolean;
	readonly analyticsExtras: any;
}

export const PopoverItem = observer(function PopoverItem({
	title,
	description,
	link,
	icon,
	isSelected,
	analyticsExtras,
}: PopoverItemProps) {
	const onClickAnalytic = (): Promise<void> =>
		getOrCreateAnalytics().track(getOrCreateAnalytics().idsNames.SummaryClick, analyticsExtras);

	const { token } = theme.useToken();

	return (
		<Container href={link} onClick={onClickAnalytic}>
			<SelectionIndicator $isSelected={isSelected} />
			<HoverContainer>
				<IconContainer $isSelected={isSelected}>{icon}</IconContainer>
				<TextContainer>
					<Typography.Text strong style={{ color: isSelected ? token.colorText : token.colorTextLabel }}>
						{title}
					</Typography.Text>
					<Typography.Text
						style={{
							color: isSelected ? token.colorTextSecondary : token.colorTextTertiary,
							fontSize: token.fontSizeSM,
						}}
					>
						{description}
					</Typography.Text>
				</TextContainer>
			</HoverContainer>
		</Container>
	);
});
