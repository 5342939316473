import { Logo, LogoSizes } from '../../assets/Logo/Logo';
import { AppsMenu } from '../../components/apps-menu/Menu';
import { UserMenuPopup } from '../../pages/common/UserMenu/UserMenuPopup';
import { FeedbackButton } from '../../pages/summary/FeedbackButton';
import { useFeatureFlag } from '../../pages/summary/behaviours/useFeatureFlag';
import { theme } from '../../theme/theme';
import { type NavinaLayout } from './LayoutType';
import styled from '@emotion/styled/macro';
import { ConfigProvider, Layout, theme as antdTheme } from 'antd';
import { observer } from 'mobx-react-lite';
import { type ComponentProps } from 'react';

const StyledLayout = styled(Layout)`
	block-size: 100%;
`;

const StyledHeader = styled.div`
	position: relative;
	background-color: ${theme.colors.primary};
	padding-block: ${theme.paddings.base};
	padding-inline: ${theme.paddings.lg};

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

const HeaderButtonsContainer = styled.div`
	display: inline-flex;
	align-items: center;
	justify-content: center;
	gap: ${theme.paddings.xs};
`;

interface BaseLayoutProps extends NavinaLayout {
	readonly headerAppMenuSelectedPage: ComponentProps<typeof AppsMenu>['selectedPage'];
}

export const HeaderConfigProvider = function (props: ComponentProps<typeof ConfigProvider>) {
	return (
		<ConfigProvider
			{...{
				theme: {
					algorithm: antdTheme.darkAlgorithm,
					// TODO - ask @shiran about the color of the button
					components: {
						Button: {
							primaryShadow: '0',
						},
					},
				},
				...props,
			}}
		/>
	);
};

export const BaseLayout = observer(function BaseLayout({
	component: Component,
	headerAppMenuSelectedPage,
	...props
}: BaseLayoutProps) {
	const showFeedbackButton = useFeatureFlag('ShowFeedbackButton', false);

	return (
		<StyledLayout>
			<StyledHeader>
				<HeaderConfigProvider>
					<Logo {...{ size: LogoSizes.Base }} />
					<HeaderButtonsContainer>
						<UserMenuPopup />
						<AppsMenu
							{...{
								selectedPage: headerAppMenuSelectedPage,
							}}
						/>
					</HeaderButtonsContainer>
				</HeaderConfigProvider>
			</StyledHeader>
			<Layout.Content>
				<Component {...props} />
			</Layout.Content>
			{showFeedbackButton && <FeedbackButton />}
		</StyledLayout>
	);
});
