import AntdTokens from '../../theme/antd-tokens/light.json';

export function getThemeValueByName(name: keyof typeof AntdTokens): string | number {
	return AntdTokens[name];
}

export function getThemeStringValueByName(name: keyof typeof AntdTokens): string {
	const value = getThemeValueByName(name);

	if (typeof value === 'number') {
		throw Error(
			`The value of the CSS variable '${name}' is a number, please use getCssVariableNumberValueByName instead`,
		);
	}

	return value;
}

export function getThemeNumericValueByName(name: keyof typeof AntdTokens): number {
	const value = getThemeValueByName(name);

	if (typeof value === 'string') {
		throw Error(
			`The value of the CSS variable '${name}' is a string, please use getCssVariableStringValueByName instead`,
		);
	}

	return value;
}
