import { colors } from '../../../base-ui/Colors';
import { CoreLoader } from '../../../base-ui/CoreLoader';
import { CoreText } from '../../../base-ui/CoreText';
import { CheckIcon } from '../../../base-ui/icons/CheckIcon';
import { type NavinaJwt, useAuthStore } from '../../../stores/authStore';
import type { AddonButtonClickBehaviorTypes } from '../../../types';
import { getOrCreateAnalytics } from '../../../utils/analytics';
import { useUserConfigValue, SetUserConfigValueState } from '../../../utils/useUserConfigValue';
import styled from '@emotion/styled/macro';
import JwtDecode from 'jwt-decode';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect } from 'react';

const OptionContainer = styled.div<{ readonly $side: 'left' | 'right'; readonly $isOn: boolean }>`
	flex: 1;
	border-radius: ${(props) => (props.$side === 'left' ? '5px 0 0 5px' : '0 5px 5px 0')};
	text-align: center;
	background-color: ${(props) => (props.$isOn ? colors.accentLightGreen : colors.white)};
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	block-size: 25px;
`;

type OptionProps = {
	side: 'left' | 'right';
	text: string;
	isOn: boolean;
	onClick: VoidFunction;
	isLoading: boolean;
};

const Option = ({ side, text, isOn, onClick, isLoading }: OptionProps) => {
	const showIsLoadingState = isLoading && isOn;

	return (
		<OptionContainer $side={side} $isOn={isOn} onClick={onClick}>
			{showIsLoadingState ? (
				<CoreLoader />
			) : (
				<CoreText color={isOn ? 'primary' : 'textGrey'} type='captionRegular' $margin='6px 2px 6px 2px'>
					{text}
				</CoreText>
			)}
		</OptionContainer>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
`;

const OptionsContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
	border-radius: 5px;
	inline-size: 170px;
	align-self: flex-end;
	margin-block-end: 5px;
`;

export const AddonClickBehaviorChooser = observer(function AddonClickBehaviorChooser() {
	const [addonButtonClickBehavior, setAddonButtonClickBehavior, addonButtonClickBehaviorState] =
		useUserConfigValue<AddonButtonClickBehaviorTypes>('addonButtonClickBehavior', 'window');

	const analytics = getOrCreateAnalytics();

	const OptionClicked = useCallback(
		(option: AddonButtonClickBehaviorTypes): VoidFunction =>
			(): void => {
				analytics.track(analytics.idsNames.SettingsClick, {
					Settings: 'NavinaOpen',
					StateSelected: option === 'tab' ? 'NewTab' : 'NewWindow',
				});

				analytics.timeEvent(analytics.idsNames.SettingsResultShown);

				setAddonButtonClickBehavior(option);
			},
		[setAddonButtonClickBehavior],
	);

	useEffect((): void => {
		let result = null;
		if (addonButtonClickBehaviorState === SetUserConfigValueState.Error) {
			result = 'Failure';
		} else if (addonButtonClickBehaviorState === SetUserConfigValueState.Success) {
			result = 'Success';
		} else {
			return;
		}

		analytics.track(analytics.idsNames.SettingsResultShown, {
			Settings: 'NavinaOpen',
			StateSelected: addonButtonClickBehavior === 'tab' ? 'NewTab' : 'NewWindow',
			Result: result,
		});
	}, [addonButtonClickBehaviorState]);

	const authStore = useAuthStore();

	let emrName = 'the EMR';
	if (authStore.getToken) {
		const tokenInfo = JwtDecode<NavinaJwt>(authStore.getToken);
		if (tokenInfo.emrName) {
			emrName = tokenInfo.emrName;
		}
	}

	return (
		<Container>
			<OptionsContainer>
				<Option
					side='left'
					text='New Tab'
					isLoading={addonButtonClickBehaviorState === SetUserConfigValueState.Loading}
					isOn={addonButtonClickBehavior === 'tab'}
					onClick={OptionClicked('tab')}
				/>
				<Option
					side='right'
					text='New Window'
					isLoading={addonButtonClickBehaviorState === SetUserConfigValueState.Loading}
					isOn={addonButtonClickBehavior === 'window'}
					onClick={OptionClicked('window')}
				/>
			</OptionsContainer>
			{addonButtonClickBehaviorState === SetUserConfigValueState.Success && (
				<CoreText type='captionRegular' $textAlign='end'>
					<CheckIcon />
					{` Settings are updated, please refresh ${emrName || 'the EMR'}`}
				</CoreText>
			)}
			{addonButtonClickBehaviorState === SetUserConfigValueState.Error && (
				<CoreText type='captionRegular' color='accentOrange' $textAlign='end'>
					Something unexpected happened, <br /> please try again later
				</CoreText>
			)}
		</Container>
	);
});
