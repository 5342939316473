import { colors } from '../../base-ui/Colors';
import { MAX_WIDTH_LOWER_WIDTH } from '../../base-ui/Resolutions';
import {
	type IZESettings,
	onZEUserEvent,
	Positions,
	setZESettings,
	TicketForms,
	hideZE,
	activateZE,
} from '../../components/contact-us/utils';
import { useAuthStore } from '../../stores/authStore';
import { getOrCreateAnalytics } from '../../utils/analytics';
import { keyframes } from '@emotion/react/macro';
import styled from '@emotion/styled/macro';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';

const ButtonContainer = styled.div`
	transform-origin: bottom right;
	transform: rotate(-90deg);
	pointer-events: auto;
`;

const Button = styled.div<{ readonly $margin?: string }>`
	background-color: ${colors.tertiary};
	border-radius: 8px 8px 0 0;
	color: ${colors.primary};
	font-weight: bold;
	min-block-size: 30px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
	inline-size: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: ${(props) => props.$margin || 'initial'};
	padding-block: 8px 28px;
	padding-inline: 13px;
	cursor: pointer;
	font-size: 13px;
	line-height: 17px;
	border: 0;
`;

const FixedButtonContainer = styled.div`
	position: fixed;
	inset-block-start: 14%;
	inset-inline-end: -20px;
	transition: 0.1s ease-in;
	pointer-events: none;
	z-index: var(--feedbackButton-zIndex);

	&:hover {
		inset-inline-end: 0;
	}

	@media ${MAX_WIDTH_LOWER_WIDTH} {
		inset-inline-end: -20px;
	}
`;

const fadeIn = keyframes`
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
`;

const OverlayContainer = styled.div`
	position: fixed;
	inset: 0;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: var(--feedbackButton-zIndex);
	-webkit-tap-highlight-color: transparent;
	animation: ${fadeIn} 0.3s ease-in;
`;

const ZENDESK_EVENTS = {
	/* cSpell:disable-next-line */
	CLOSE_ID: 'Web Widget Minimised',
} as const;

export const FeedbackButton = observer(function FeedbackButton() {
	const authStore = useAuthStore();
	const [showFullscreenBackground, setShowFullscreenBackground] = useState<boolean>(false);

	useEffect((): void => {
		onZEUserEvent((userEvent): void => {
			if (userEvent.action === ZENDESK_EVENTS.CLOSE_ID) {
				setShowFullscreenBackground(false);
			}
		});
	}, []);

	const closeFeedbackModal = (): void => {
		setShowFullscreenBackground(false);
		hideZE();
	};

	const setSettings = (): void => {
		const settings: IZESettings = {
			horizontalPosition: Positions.RIGHT,
			verticalPosition: Positions.BOTTOM,
			offsetX: window.innerWidth / 2 - 170,
			offsetY: window.innerHeight / 2 - 285,
			formTitle: 'Contact Us',
			attachments: false,
			fieldsPrefill: [
				{ id: 'name', value: authStore.userFullName },
				{ id: 'email', value: localStorage.getItem('navinaEmail') || authStore.getEmail },
			],
			ticketForms: [TicketForms.CONTACT_US, TicketForms.FEEDBACK],
		};

		setZESettings(settings);
		setShowFullscreenBackground(true);
	};

	const openFeedbackModal = (): void => {
		setSettings();
		activateZE();
		getOrCreateAnalytics().track(getOrCreateAnalytics().idsNames.FeedbackClicked);
	};

	return (
		<>
			<FixedButtonContainer>
				<ButtonContainer>
					<Button {...{ onClick: openFeedbackModal }}>Submit Feedback</Button>
				</ButtonContainer>
			</FixedButtonContainer>
			{showFullscreenBackground && (
				<OverlayContainer
					{...{
						onClick: closeFeedbackModal,
					}}
				/>
			)}
		</>
	);
});
