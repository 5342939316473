import { Logo, LogoSizes } from '../../assets/Logo/Logo';
import { colors } from '../../base-ui/Colors';
import { AppsMenu } from '../../components/apps-menu/Menu';
import { UserMenuPopup } from '../../pages/common/UserMenu/UserMenuPopup';
import { BackgroundCell } from '../../pages/summary/summaryStyles';
import { HeaderConfigProvider } from './BaseLayout';
import type { NavinaLayout } from './LayoutType';
import styled from '@emotion/styled/macro';
import { observer } from 'mobx-react-lite';
import { Suspense } from 'react';

const PageContainer = styled.div`
	block-size: 100%;
	inline-size: 100%;
	overflow: hidden;
	display: grid;
	grid-template-columns: 4fr minmax(0, 25fr) 4fr;
	grid-template-rows: 80px 80px auto;
	background-color: ${colors.background};
`;

const ComponentContainer = styled.div`
	background-color: ${colors.background};
	border-radius: 8px 8px 0 0;
	grid-column: 2;
	grid-row: 2 / span 2;
	box-shadow: 0 10px 15px -3px rgba(50, 43, 95, 0.5);
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	scroll-behavior: smooth;
`;

const LogoContainer = styled.div`
	grid-row: 1;
	grid-column: 1;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding-inline-start: 30px;
`;

const UserMenuContainer = styled.div`
	grid-row: 1;
	grid-column: 3;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 7px;
`;

interface SummaryLayoutProps extends NavinaLayout {}

export const SummaryLayout = observer(function SummaryLayout({ component: Component, ...props }: SummaryLayoutProps) {
	return (
		<Suspense fallback={<div />}>
			<PageContainer>
				<BackgroundCell $row='1 / span 2' $column='1 / span 3' />
				<HeaderConfigProvider>
					<LogoContainer>
						<Logo {...{ size: LogoSizes.Large }} />
					</LogoContainer>
					<UserMenuContainer>
						<UserMenuPopup />
						<AppsMenu selectedPage='patient-portrait' />
					</UserMenuContainer>
				</HeaderConfigProvider>
				<ComponentContainer>
					<Component {...props} />
				</ComponentContainer>
			</PageContainer>
		</Suspense>
	);
});
