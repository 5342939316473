import { useNavinaData } from '../../utils/useNavinaData';
import { ReactComponent as NavinaLogoIcon } from './Logo.svg';
import styled from '@emotion/styled/macro';
import { observer } from 'mobx-react-lite';
import { type ComponentProps } from 'react';
import { Link } from 'react-router-dom';

export enum LogoSizes {
	Small = 'Small',
	Base = 'Base',
	Large = 'Large',
}

const LogoPixelSizesMap = {
	[LogoSizes.Small]: 30,
	[LogoSizes.Base]: 38,
	[LogoSizes.Large]: 44,
} as const satisfies Record<LogoSizes, number>;

const LogoLinkContainer = styled(Link)`
	&,
	&:hover,
	&:focus,
	&:active {
		color: white;
	}

	display: inline-flex;
	align-items: center;
	justify-content: center;

	block-size: var(--container-size);
	inline-size: var(--container-size);
`;

const LogoContainer = LogoLinkContainer.withComponent('div');

interface LogoProps extends Partial<ComponentProps<typeof LogoLinkContainer>> {
	readonly size: LogoSizes;
	readonly forwardToHomePage?: boolean;
}

export const Logo = observer(function Logo({
	style,
	forwardToHomePage: outerForwardToHomePage = true,
	children = <NavinaLogoIcon />,
	...props
}: LogoProps) {
	const { isFullScreen } = useNavinaData();
	const forwardToHomePage: boolean = isFullScreen && outerForwardToHomePage;
	console.log('TODO - implement this feature', forwardToHomePage);

	const Container = forwardToHomePage ? LogoLinkContainer : LogoContainer;

	return (
		<Container
			{...{
				to: '/',
				'aria-label': 'Move to the home page',
				style: {
					...style,
					'--container-size': `${LogoPixelSizesMap[props.size]}px`,
				},
				children,
				...props,
			}}
		/>
	);
});
